import conf from "./config.js";
import axios from 'axios'
import store from '@/store'
import {getToken} from './auth'
import errorCode from './errorCode'
import Vue from 'vue'
import {ToastPlugin} from 'bootstrap-vue'

Vue.use(ToastPlugin)

let vm = new Vue()

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: conf.apiHost,
  // 超时
  timeout: 120000
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  config.headers['X-TENANT'] = "bbb9e8ee831348d983cedca1690d8047";//租户id
  config.headers['Accept-Language'] = "en";//语言

  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?';
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof (value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            if (value[key] !== null && typeof (value[key]) !== 'undefined') {
              let params = propName + '[' + key + ']';
              let subPart = encodeURIComponent(params) + '=';
              url += subPart + encodeURIComponent(value[key]) + '&';
            }
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 401) {
      vm.$bvToast.toast("Invalid session, or the session has expired, please log in again", {
        title: "Tips",
        variant: "danger",
        toaster: 'b-toaster-top-center',
        solid: true,
      })
      // Toast('登录状态已过期，您可以继续留在该页面，或者重新登录');
      store.dispatch('LogOut').then(() => {
        location.href = '/index_v1';
      })
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
      if ('The operation is too frequent, please try again later.' === msg) {
        return Promise.reject(msg);
      }
      vm.$bvToast.toast(msg, {
        title: "Tips",
        variant: "danger",
        toaster: 'b-toaster-top-center',
        solid: true,
      })
      return Promise.reject(msg)
    } else if (code !== 200) {
      vm.$bvToast.toast(msg, {
        title: "Tips",
        variant: "danger",
        toaster: 'b-toaster-top-center',
        solid: true,
      })
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error)
    let {message} = error;
    if (message == "Network Error") {
      message = "网络不稳定，请稍后再试";
    } else if (message.includes("timeout")) {
      message = "网络不稳定，请稍后再试";
    } else if (message.includes("Request failed with status code")) {
      message = "服务接口" + message.substr(message.length - 3) + "异常";
    }
    vm.$bvToast.toast(message, {
      title: "Tips",
      variant: "danger",
      toaster: 'b-toaster-top-center',
      solid: true,
    })
    return Promise.reject(error)
  }
)

export default service
