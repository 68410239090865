import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

//路由冗余
const r = Router.prototype.push;
Router.prototype.push = function push(locat){
  return r.call( this , locat).catch(err => err)
}

// 公共路由
export const constantRoutes = [
  {
    path: '/',
    redirect: '/index_v1'
  },
  {
    path: '/login',
    name: 'login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true,
    meta: { title: '登录'}
  },
  {
    path: '/index_v1',
    component: (resolve) => require(['@/views/index_v1'], resolve),
    hidden: true,
    meta: { title: '首页'}
  },
  {
    path: '/index',
    component: (resolve) => require(['@/views/index'], resolve),
    hidden: true,
    meta: { title: '首页'}
  },
  {
    path: '/faq',
    component: (resolve) => require(['@/views/faq'], resolve),
    hidden: true,
    meta: { title: 'FAQ'}
  },
  {
    path: '/aboutUs',
    component: (resolve) => require(['@/views/aboutUs'], resolve),
    hidden: true,
    meta: { title: 'About Us'}
  },
  {
    path: '/setting',
    component: (resolve) => require(['@/views/setting'], resolve),
    hidden: true,
    meta: { title: 'Setting'}
  }
]

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
