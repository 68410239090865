import { login, register, logout } from '@/api/login'
import { getToken, setToken, removeToken } from '@/api/auth'
import { getInfo } from "@/api/user";
import config from "@/api/config.js";
import Cookies from "js-cookie";

const user = {
  state: {
    token: getToken(),
    userInfo: '',
    roles: [],
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_USERINFO: (state, user) => {
      state.userInfo = user
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const email = userInfo.email;
      const password = userInfo.password.trim()
      return new Promise((resolve, reject) => {
        login(email, password).then(res => {
          setToken(res.token)
          commit('SET_TOKEN', res.token)
          commit('SET_USERINFO', res.user)
          resolve(res.user)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 注册
    Register({ commit }, registerForm) {
      return new Promise((resolve, reject) => {
        register(registerForm).then(res => {
          setToken(res.token)
          commit('SET_TOKEN', res.token)
          commit('SET_USERINFO', res.user)
          resolve(res.user)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          commit('SET_USERINFO', res.data)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        resolve()
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
