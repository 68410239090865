import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import router from './router'
import './plugins/bootstrap-vue'
import App from './App.vue'
import store from './store'
import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.config.productionTip = false

document.title = "DaVinci"

Vue.prototype.$NumFormat = function (value) {
  if (!value) return "0";
  // 提取字符串中的数字
  var numArr = value.toString().match(/-?([0-9]\d*(\.\d*)*|0\.[0-9]\d*)/g)
  value = numArr.join('');

  var intPart = Number(value) | 0; //获取整数部分
  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断
  var floatPart = "00"; //预定义小数部分

  var value2Array = value.toString().split(".");

  //=2表示数据有小数位
  if (value2Array.length == 2) {
    floatPart = value2Array[1].toString(); //拿到小数部分
    if (floatPart.length == 1) {
      //补0,实际上用不着
      return intPartFormat + "." + floatPart + "0";
    } else {
      return intPartFormat + "." + floatPart;
    }
  } else {
    return intPartFormat + "." + floatPart;
  }
}

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
