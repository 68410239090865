<template>
  <div class="container theader-navbar pt-3 pb-3">
    <div class="row">
      <div class="col-3 d-flex">
        <div class="d-flex align-items-center"  @click="toHome" style="cursor: pointer;">
          <img src="../assets/logo3.png" style="cursor: pointer;width: 1.8rem;" />
          <h2 class="mb-0 ml-1" :style="{'color': logoColor}">DaVinci</h2>
        </div>
      </div>
      <div class="col-9 d-none d-lg-block d-xl-block">
        <b-nav class="nav nav-pills" :class="{'isabout': colorChange}" align="right" style="align-items: center;">
          <b-nav-item @click="toHome"><span class="one">Home</span></b-nav-item>
          <b-nav-item @click="toFAQ"><span class="one">FAQ</span></b-nav-item>
          <b-nav-item @click="toAboutUs"><span class="one">About Us</span></b-nav-item>
          <div class="column-line" v-if="!$store.state.user.token"></div>
          <b-nav-item :active="tag===1"  @click="toLoginIn" v-if="!$store.state.user.token"><span class="one">Log In</span></b-nav-item>
          <b-nav-item :active="tag===2" v-if="!$store.state.user.token" @click="toSignUp"><span class="one">Sign Up</span></b-nav-item>
          <div class="draw_down_content" v-if="$store.state.user.token">
            <div class="draw_down_title" @click="showmoonItems = !showmoonItems">DAVINCI <i class="icon icon-xiala"></i></div>
            <div class="draw_down_items" v-if="showmoonItems">
              <div class="two" @click="toSetting">Settings</div>
              <div class="two" @click="onlogOut">Log Out</div>
            </div>
          </div>
        </b-nav>
      </div>
      <div class="col-9 d-block d-lg-none d-xl-none" :class="{'isabout': colorChange}">
        <b-nav class="nav nav-pills" align="right" style="align-items: center;margin-right: -1rem;" v-if="!showDrawer">
          <b-nav-item @click="showDrawerClick"><b-icon icon="list"></b-icon></b-nav-item>
        </b-nav>
      </div>
    </div>
    
    <div class="drawer_container " :class=" showDrawer? 'drawer_container_show ': 'drawer_container_hidden' ">
      <div class="drawer_content " :class=" showDrawer? 'drawer_content_show ': 'drawer_content_hidden'">
        <div class="close_drawer"><b-icon icon="x" @click="closeDrawer"></b-icon></div>
        <b-nav class="nav" vertical >
          <b-nav-item @click="toHome"><span class="one"><i class="icon icon-home pr-3"></i>Home</span></b-nav-item>
          <b-nav-item @click="toFAQ"><span class="one"><i class="icon icon-faq pr-3"></i>FAQ</span></b-nav-item>
          <b-nav-item @click="toAboutUs"><span class="one"><i class="icon icon-about pr-3"></i>About Us</span></b-nav-item>
          <b-nav-item @click="toLoginIn" v-if="!$store.state.user.token" ><span class="one"><i class="icon icon-login " style="font-size: 1.8rem;padding-right: 0.7rem;"></i>Log In</span></b-nav-item>
          <b-nav-item @click="toSignUp" v-if="!$store.state.user.token"><span class="one"><i class="icon icon-signin " style="font-size: 1.8rem;padding-right: 0.7rem;"></i>Sign Up</span></b-nav-item>
          <b-nav-item @click="toSetting" v-if="$store.state.user.token"><span class="one"><i class="icon icon-setting  pr-3"></i>Settings</span></b-nav-item>
          <!-- <b-nav-item v-if="$store.state.user.token"><span class="one"><i class="icon icon-support  pr-3"></i>Support</span></b-nav-item> -->
          <b-nav-item @click="onlogOut" v-if="$store.state.user.token"><span class="one"><i class="icon icon-logout pr-3"></i>Log Out</span></b-nav-item>
        </b-nav>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        tag: 1,//1登录 2注册
        showDrawer: false,
        showmoonItems: false,
        logoColor: "#333",
        colorChange: false,
      }
    },
    props: {
      tagLogin: {
        type:Number,
        default: 1,
      },
    },
    watch:{
      '$route': function(val){
        this.showDrawer = false;//跳转页面，侧边栏收起
        if(this.$route.params && this.$route.params.tag){
          this.tag = this.$route.params.tag;
        }
        if(this.$route.query.scode){
          this.tag = 2
        }
        if( this.$route.path.indexOf('login') >= 0 || this.$route.path.indexOf('aboutUs') >= 0){
          this.logoColor = "#fff";
          this.colorChange = true;
        } else {
          this.logoColor = "#333";
          this.colorChange = false;
        }
      },
      'tagLogin': function(val){
        this.tag = val;
      }
    },
    created() {
    },
    methods: {
      toHome(){
        if( this.$store.state.user.token ){
          this.$router.push({
            path:'/index'
          })
        } else {
          this.$router.push({
            path: 'index_v1'
          })
        }
        this.tag = 1;
        this.showDrawer = false;
      },
      toFAQ(){
        this.tag = 1;
        this.$router.push({
          path: "/faq"
        })
      },
      toAboutUs(){
        this.tag = 1;
        this.$router.push({
          path: "/aboutUs"
        })
        this.showmoonItems = false;
      },
      toSetting(){
        this.$router.push({
          path: "/setting"
        })
        this.showmoonItems = false;
      },
      showDrawerClick(){
        this.showDrawer = true;
      },
      closeDrawer(){
        this.showDrawer = false;
      },
      toLoginIn(){
        this.tag = 1;
        this.$emit("tagLoginSign", 1);
        this.$router.push({
          name: "login",
          params: {tag: 1},
        })
        this.showDrawer = false;
      },
      toSignUp(){
        this.tag = 2;
        this.$emit("tagLoginSign", 2);
        this.$router.push({
          name: "login",
          params: {tag: 2},
        })
      },
      onlogOut(){
        this.$store.dispatch('LogOut').then(() => {
          location.href = '/login';
        })
        this.showDrawer = false;
      },
    }
  }
</script>

<style lang="scss">
  .theader-navbar {
    .draw_down_content {
      position: relative;
      color: #000;
      
      .draw_down_title {
        padding: 0.5rem;
        cursor: pointer;
        &:hover {
          color: #333;
        }
      }
      
      .draw_down_items {
        position: absolute;
        left: 0rem;
        right: 0;
        z-index: 8;
        background-color: #fff;
        padding: 0 0.5rem;
        border-radius: 0 0 2px 2px;
        color: #888;
        .two {
          cursor: pointer;
          line-height: 2;
        }
        .two:hover {
          color: #333;
        }
      }
    }
    .nav-item {
      a {
        color: #000;
      }
      .nav-link {
        &:hover .one {
          color: #333;
        }
      }
      
      .nav-link.active {
        &:hover {
          color: #fff;
          background-color: #0069d9;
        }
      }
    }
    
    .isabout {
      .draw_down_content {
        color: #fff;
        .draw_down_title {
          &:hover {
            color: #888;
          }
        }
      }
      a {
        color: #fff;
      }
      .nav-item .nav-link {
        &:hover .one {
          color: #888;
        }
      }
    }
    .column-line {
      width: 1px;
      background-color: #888;
      height: 1em;
      margin: 0 5px;
    }
    
    .drawer_container {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #00000000;
      z-index: 99;
      .drawer_content {
        width: 200px;
        height: 100%;
        padding: 10px;
        background-color: #fff;
        margin-left: 100%;
        
        .close_drawer {
          display: flex;
          justify-content: flex-end;
          font-size: 25px;
          color: #888;
        }
        
        .nav-item {
          font-size: 1.3rem;
          line-height: 1.5;
          a {
            color: #898989;
          }
          i{
            font-size: 1.3rem;
          }
        }
      }
    }
    
    .drawer_content_show {
      transition: transform 0.2s ease-in 0s;
      transform: translateX(-100%);
      z-index: 99;
    }
    .drawer_container_show {
      transition: all 0.2s ease-in 0s;
      background-color: #0000006b;
      visibility: visible;
      z-index: 99;
    }
    .drawer_content_hidden {
      transition: transform 0.2s ease-in 0s;
      transform: translateX(0%);
    }
    .drawer_container_hidden {
      transition: all 0.2s ease-in 0s;
      background-color: #00000000;
      visibility: hidden;
    }
    
    .collapse {
      .card {
        border: 0;
        
        .card-body {
          padding: 0 1em;
        }
      }
    }
  }
</style>
