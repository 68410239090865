import request from './request'

// 获取用户信息
export function getInfo() {
  return request({
    url: '/api/user',
    headers: {isToken: true},
    method: 'get'
  })
}

// 编辑用户信息 nickName usdtAddress
export function editInfo(obj) {
  return request({
    url: '/api/user',
    headers: {isToken: true},
    method: 'put',
    data: obj
  })
}

// 找回密码 code newPassword oldPassword
export function findPassword(data) {
  return request({
    url: '/api/user/retrievePwd',
    headers: {isToken: false},
    method: 'put',
    data: data
  })
}

// 重置密码 newPassword oldPassword
export function resetPassword(data) {
  return request({
    url: '/api/user/updatePwd',
    headers: {isToken: true},
    method: 'put',
    data: data
  })
}

// 使用会员升级码
export function setVipUpgradeCode(code) {
  return request({
    url: '/api/user/vip/upgrade',
    headers: {isToken: true},
    method: 'put',
    data: {code: code}
  })
}

// 申领分享码
export function applyShareCode() {
  return request({
    url: '/api/user/create/shareCode',
    headers: {isToken: true},
    method: 'post'
  })
}

/**
 * 获取分享链接注册用户列表
 */
export function listShareUser() {
  return request({
    url: '/api/user/list/shareUser',
    headers: {isToken: true},
    method: 'get'
  })
}
