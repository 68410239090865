<template>
  <div
    id="app"
    class="index_bg"
    :style="{
      'background-image': 'url(' + bgImg + ')',
      'background-size': bgSize,
    }"
  >
    <div style="padding-bottom: 100px">
      <theader-navbar
        @tagLoginSign="(val) => (tagLogin = val)"
        :tagLogin="tagLogin"
      ></theader-navbar>
      <div class="container email_alert">
        <b-alert
          variant="warning"
          :show="
            isEmailAuth &&
            $route.path.indexOf('aboutUs') == -1 &&
            $route.path.indexOf('login') == -1
          "
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="mr-3">
              <div>Email not authenticated</div>
              <div class="text">
                This email will be used for password modification
              </div>
            </div>
            <b-link @click="toAuth">authentication</b-link>
          </div>
        </b-alert>
      </div>
      <router-view
        @isAuthEmail="isAuthEmail"
        @imageBg="setBg"
        :taglogin="tagLogin"
        @tagLSchange="(val) => (tagLogin = val)"
        class="routerview_container"
      ></router-view>
    </div>
    <tfoot-container></tfoot-container>
  </div>
</template>

<script>
import theaderNavbar from '@/components/theader-navbar.vue';
import tfootContainer from '@/components/tfoot-container.vue';
import Cookies from 'js-cookie';
import { getInfo } from '@/api/user';

export default {
  name: 'App',
  components: {
    theaderNavbar,
    tfootContainer,
  },
  data() {
    return {
      tagLogin: 1,
      bgImg: '',
      bgSize: 'contain',
      isEmailAuth: false,
    };
  },
  created() {
    if (this.$store.state.user.token) {
      this.$router.replace({
        path: '/index',
      });
    }
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      if (this.$store.state.user.token) {
        getInfo().then((res) => {
          if (res.data.authStatus == 0) {
            this.isEmailAuth = true;
          } else {
            this.isEmailAuth = false;
          }
        });
      }
    },
    isAuthEmail(val) {
      if (val == 0) {
        this.isEmailAuth = true;
      } else {
        this.isEmailAuth = false;
      }
    },
    toAuth() {
      this.$router.push({
        path: '/setting?tag=' + 'email',
      });
    },
    setBg(obj) {
      this.bgImg = obj.bg ? obj.bg : '';
      this.bgSize = obj.size ? obj.size : 'contain';
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/vendors/bootstrap-vue/index';
@import '~@/assets/iconfont/iconfont.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
}

.index_bg {
  // background-image: url();
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: right top;
}

*:focus {
  outline: none;
}

input:focus {
  outline: none;
}

.form-control:focus,
.btn:focus {
  box-shadow: 0 0 0 0 !important;
}

.email_alert {
  font-size: 1rem;

  .text {
    font-size: 0.8rem;
    color: #aa9090;
  }
}

.routerview_container {
  // min-height: 45vh;
  // padding-bottom: 100px;
}

.btn-primary {
  background-color: $theme !important;
  border-color: $theme !important;

  &:hover {
    background-color: $btn-color;
    border-color: $btn-color;
  }
}
</style>
