import request from './request'

// 登录方法
export function login(email, password) {
  const data = {
    email,
    password
  }
  return request({
    url: '/api/auth/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法 email,name, password
export function register(obj) {
  return request({
    url: '/api/auth/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: obj
  })
}

// 发送验证码 email (传递则是找回密码，不传参就是登录用户邮箱验证)
export function sendCode(email) {
  return request({
    url: `/api/auth/code/send`,
    headers: {
      isToken: true
    },
    method: 'get',
    params: {
      email: email
    }
  })
}

// 校验验证码 code
export function checkCode(code) {
  return request({
    url: '/api/auth/code/check',
    headers: {
      isToken: true
    },
    method: 'get',
    params: {
      code: code,
    }
  })
}

